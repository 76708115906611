import React from "react"
import Link from "gatsby-link"
import { graphql } from "gatsby"
import queryTools from "../utils/queryParsing"
import FactBox from "../components/Factbox"
import { css } from "@emotion/core"
// import styles from "../styles/glitchFrenchWave.module.css"
import styles from "../styles/strongSlant.module.css"
import Helmet from "react-helmet"
import SEO from "../components/SEO"
import SiteContainer from "../components/SiteContainer"

export default data => {
	// console.log("Data: ", data.data)
	console.log("FNW", styles)
	console.log("hpachiev", data.data.allAchievement.edges)
	var styleSet = [styles.fullBox].join(" ")
	// {data.data.allFile.totalCount}
	var breakName = {
		__html: queryTools.resolveSiteMetadataEdge("bigName", data.data),
	}
	var informerText = {
		__html: queryTools.resolveSiteMetadataEdge("intro", data.data),
	}
	var lastBuildTime = new Date(
		queryTools.resolveSiteMetadataEdge("dateModified", data.data)
	).toDateString()
	var title = queryTools.resolveSiteMetadataEdge("title", data.data)
	var styleBlockOne = {
		//marginTop: "-6vw",
		zIndex: "50",
		position: "relative",
		margin: "-62px 0 0 15px",
		width: "calc(100% - 5% - 15px)",
	}
	var styleBlockTwo = {
		color: "black",
		// fontSize: "140px",
		fontWeight: "500",
		fontSize: "24px",
		zIndex: "55",
		fontVariant: "all-small-caps",
		backgroundColor: "var(--f-overlay)",
		width: "fit-content",
		padding: "3px",
		marginBottom: "0",
	}
	styleBlockOne = {}
	styleBlockTwo = {}
	var highlightStyle = `.highlight {
		background-color: var(--light-yellow);
		padding: 0.25em;
	}`
	return (
		<SiteContainer>
			<style>{highlightStyle}</style>
			<div id={styles.homepage}>
				<SEO
					postMeta={{
						title,
						slug: "/",
					}}
					postDefaults={{
						description:
							"Jordan Gass-Pooré is a New York City-based podcast producer and investigative journalist with more than a decade of journalism experience.",
					}}
					postPath={"topics-in-focus/"}
					isType="website"
					typeMeta={{
						publisher_name: "Jordan Gass-Poore'",
					}}
				/>
				<div className={styleSet}>
					<span className={styles.siteBreadCrumb}>
						{queryTools.resolveSiteMetadataEdge("title", data.data)}:{" "}
						<span className={styles.highlight}>Home Page</span>
					</span>
					<h1
						className={styles.homepageTitle}
						dangerouslySetInnerHTML={breakName}
					></h1>
				</div>
				<div style={styleBlockOne}>
					<h4 style={styleBlockTwo}>Pages:</h4>
					<div className={styles.fnwMenuBox}>
						<ul>
							{data.data.allFile.nodes.map(node => (
								<Link
									to={node.fields.slug}
									style={{
										textDecoration: "none",
									}}
									key={node.childMarkdownRemark.id}
								>
									<li key={node.childMarkdownRemark.id}>
										<span
											style={{
												textDecoration: "none",
											}}
										>
											<h5>{node.childMarkdownRemark.frontmatter.title}</h5>
										</span>
									</li>
								</Link>
							))}
							<Link
								to="resume"
								style={{
									textDecoration: "none",
								}}
								key="resume"
							>
								<li key="resume-li">
									<span
										style={{
											textDecoration: "none",
										}}
									>
										<h5>Resume</h5>
									</span>
								</li>
							</Link>
							<Link
								to="map"
								style={{
									textDecoration: "none",
								}}
								key="map"
							>
								<li key="map-li">
									<span
										style={{
											textDecoration: "none",
										}}
									>
										<h5>Locations Covered</h5>
									</span>
								</li>
							</Link>
							<Link
								to="contact"
								style={{
									textDecoration: "none",
								}}
								key="contact"
							>
								<li key="contact-li">
									<span
										style={{
											textDecoration: "none",
										}}
									>
										<h5>Contact Me</h5>
									</span>
								</li>
							</Link>
						</ul>
						<div
							className={styles.informer}
							dangerouslySetInnerHTML={informerText}
						></div>
						<div className={styles.meta}>
							Last Updated: <br />{" "}
							<span className={styles.buildtime}>{lastBuildTime}</span>
						</div>
					</div>
				</div>

				<div className={styles.lowerFwBoxContainer}>
					<div className={styles.lowerFwBox}>
						<div className={styles.factBoxContainer}>
							<FactBox boxTitle="Location">
								<h6>I'm working in:</h6>
								<p>
									<span className={styles.highlight}>New York City</span>, New
									Jersey, Washington, DC
								</p>
							</FactBox>

							<FactBox boxTitle="Other Sites">
								<ul>
									<li>
										<a
											href="https://www.localswitchboard.nyc/"
											target="_blank"
											rel="noreferrer"
											className={styles.highlight}
										>
											Local Switchboard
										</a>
									</li>
									<li>Book Club Girls</li>
									<li>
										<a
											href="https://www.motherjones.com/author/jordan-gass-poore/"
											target="_blank"
											rel="noreferrer"
										>
											Mother Jones
										</a>
									</li>
									<li>
										<a
											href="https://www.cnn.com/profiles/jordan-gass-poore"
											target="_blank"
											rel="noreferrer"
											className={styles.highlight}
										>
											CNN
										</a>
									</li>
									<li>
										<a
											href="https://www.npr.org/sections/npr-extra/2016/05/06/476881707/npr-interns-present-off-mic-a-behind-the-scenes-report-about-how-radio-gets-done"
											target="_blank"
											rel="noreferrer"
										>
											NPR: Off Mic
										</a>
									</li>
								</ul>
							</FactBox>

							<FactBox boxTitle="Clips">
								<h6>Current Number:</h6>
								<p className={styles.highlight}>
									{data.data.allClip.totalCount}
								</p>
							</FactBox>

							<FactBox boxTitle="Social">
								<h6 className={styles.highlight}>Follow Me:</h6>
								<ul>
									<li>
										<a href="https://twitter.com/jgasspoore" target="_blank">
											Twitter
										</a>
									</li>
									<li>
										<a
											href="https://www.instagram.com/jgasspoore/"
											target="_blank"
										>
											Instagram
										</a>
									</li>
									<li>
										<a
											href="https://www.facebook.com/jordanpodcasts/"
											target="_blank"
										>
											Facebook
										</a>
									</li>
								</ul>
							</FactBox>

							<FactBox boxTitle="Education">
								<ul>
									{data.data.allAchievement.edges.map(({ node }, index) => (
										<li key={node.id}>
											<Link
												to={"/achievement/" + node.achievement.slug}
												style={{
													textDecoration: "none",
												}}
												key={node.id}
											>
												<span
													style={{
														textDecoration: "underline",
													}}
												>
													<span
														className={
															index > 1 && index < 3 ? styles.highlight : ""
														}
													>
														{node.achievement.title}
													</span>
												</span>
											</Link>
										</li>
									))}
								</ul>
							</FactBox>
						</div>
					</div>
				</div>
			</div>
		</SiteContainer>
	)
}

export const query = graphql`
	query siteMeta {
		__typename
		allSite {
			edges {
				node {
					siteMetadata {
						title
						description
						name
						bigName
						intro
						dateModified
					}
				}
			}
		}
		allFile(
			filter: {
				sourceInstanceName: { eq: "basePages" }
				fields: { slug: { ne: null } }
			}
		) {
			nodes {
				relativePath
				base
				name
				sourceInstanceName
				internal {
					type
					contentDigest
					description
					mediaType
				}
				fields {
					slug
				}
				childMarkdownRemark {
					id
					html
					frontmatter {
						title
					}
				}
			}
			totalCount
		}
		allClip {
			totalCount
		}
		allAchievement(
			filter: {
				achievement: {
					type: { eq: "Education" }
					affiliation: { regex: "/Coll|Uni/" }
					title: { regex: "/Degree|Master|Bachelor/" }
				}
			}
		) {
			edges {
				node {
					id
					achievement {
						title
						slug
					}
				}
			}
		}
	}
`
